<template>
  <div>
    <CCol md="12">
      <CCard>
        <CCardBody>
          <loader :loading="requisicaoAberta"></loader>
          <CDataTable
            class="table-outline"
            hover
            :items="projetos"
            :fields="CamposTabela"
            head-color="light"
            sorter
            :loading="carregandoProjetos"
          >
            <td slot="acoes" class="text-center" slot-scope="{item}">
              <CButtonGroup size="sm">
                <CButton color="danger" @click="excluirProjeto(item.id)">
                  <CIcon name="cil-trash" />
                </CButton>
                <CButton color="info" @click="abrirProjeto(item.id)">
                  <CIcon name="cil-folder-open" />
                </CButton>
              </CButtonGroup>
            </td>
            <template slot="no-items-view">
              <div class="text-center my-5">
                <h2 v-if="!carregandoProjetos">Nenhum projeto cadastrado!!!</h2>
              </div>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CButton @click="formularioCadastroProjeto = true" color="primary" class="float-right">
            <CIcon name="cil-notes" />&nbsp;Novo Projeto
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      title="Modal title"
      :show.sync="formularioCadastroProjeto"
      size="lg"
      addContentClasses="card"
      :closeOnBackdrop="false"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Dados do projeto
          <CButtonClose
            @click="formularioCadastroProjeto = false"
            buttonClasses="text-muted close"
          />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <SBFormularioDados :enviar="enviarFormulario" @projetoSalvo="projetoCadastroSucesso"></SBFormularioDados>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" size="sm" class="float-right" @click="salvarProjeto">
            <CIcon name="cil-save"></CIcon>&nbsp;Salvar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
  </div>
</template>

<script>
import Axios from "axios";
import Meta from "./../shared/meta-dados";
import SBFormularioDados from "./Dados/Formulario";
import Loader from "./Componentes/loader";
export default {
  name: "Dashboard",
  components: {
    SBFormularioDados,
    Loader
  },
  data() {
    return {
      formularioCadastroProjeto: false,
      enviarFormulario: false,
      projetos: [],
      requisicaoAberta: false,
      carregandoProjetos: false,
      CamposTabela: [
        { key: "idLinha", sorter: true, label: "#", _classes: "text-center" },
        { key: "prefixo", _classes: "text-center", sorter: true },
        { key: "projeto", sortable: true },
        { key: "cliente", sorter: true },
        { key: "cidade", sorter: false },
        { key: "estado", sorter: false },
        { key: "acoes", label: "Ações", sorter: false }
      ]
    };
  },
  methods: {
    obtenhaProjetos() {
      this.carregandoProjetos = true;
      Axios.get("/projeto").then(response => {
        this.projetos = response.data.retorno.map((projeto, index) => {
          projeto.idLinha = index + 1;
          return projeto;
        });
        this.carregandoProjetos = false;
      });
    },

    abrirProjeto(idProjeto) {
      this.$router.push({
        name: "Dados",
        params: { projetoID: idProjeto }
      });
    },
    salvarProjeto() {
      this.$eventoHub.$emit("salvarProjeto", true);
    },
    excluirProjeto(idProjeto) {
      this.$swal({
        title: "Atenção",
        icon: "warning",
        text: Meta.obtenhaMensagem(1001, "pt-br"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          this.requisicaoAberta = true;
          Axios.delete("/projeto/" + idProjeto)
            .then(() => {
              this.$swal(
                "Removido!",
                Meta.obtenhaMensagem(1010, "pt-br"),
                "success"
              );
              this.obtenhaProjetos();
            })
            .catch(error => {

            })
            .finally(() => {
              this.requisicaoAberta = false;
            });
        }
      });
    },
    projetoCadastroSucesso(projeto) {
      this.abrirProjeto(projeto.id);
    }
  },
  mounted: function() {
    this.obtenhaProjetos();
  }
};
</script>
